import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator} from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";
import { getStorage, connectStorageEmulator  } from "firebase/storage";

import { getApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCqzGxAhlIULWEOZnX5FyRpHkprACqaV0A",
  authDomain: "rb-backup2.firebaseapp.com",
  projectId: "rb-backup2",
  storageBucket: "rb-backup2.appspot.com",
  messagingSenderId: "657207266398",
  appId: "1:657207266398:web:11ee477206311c6456f8cf"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const firestore = getFirestore(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);

//Firebase Emulator:

if (window.location.hostname.includes("localhost")) {
  connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  connectStorageEmulator(storage, "127.0.0.1", 9199);
}