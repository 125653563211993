import React, { useState, useEffect } from 'react'
import { Rating } from 'react-simple-star-rating'
import './App.css';
import { useParams } from 'react-router-dom';
import { firestore } from './firebase';
import qs from 'qs';
import { TextInput } from '@mantine/core';
import { Textarea } from '@mantine/core';

import { collection, addDoc, doc, getDoc } from "firebase/firestore"; // Import the functions you need from the SDKs you need
import shortUUID from 'short-uuid';
let timeout;
let locationSearch = null
function RatingScreen() {

  const queryString = window.location.search.slice(1); // Entferne das "?" am Anfang
  const queryParams = qs.parse(queryString);

  // Zugriff auf einzelne Parameter
  const rating = queryParams.rating ?? 0;
  const campaign = queryParams.campaign ?? null;

  const [ratingValue, setRatingValue] = useState(rating)
  const [displayState, setDisplayState] = useState("rating")
  
  const [textareaValue, setTextareaValue] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');

  const [locationName, setLocationName] = useState("");
  const [reviewLink, setReviewLink] = useState("");
  const [logoURL, setLogoURL] = useState("");
  const { venueKey } = useParams();
  const [sessionId, setSessionId] = useState("");

  const short = require('short-uuid');
  const translator = short();

  console.log('Rating:', rating);
  console.log('Campaign:', campaign);

  useEffect(() => {
    if (!locationSearch) {
      locationSearch = venueKey

      const fetchData = async () => {
        try {
          const collectionRef = collection(firestore, 'locations');
          const docRef = doc(collectionRef, venueKey);
          const docSnapshot = await getDoc(docRef);

          if (docSnapshot.exists) {
            // setLocationData(docSnapshot.data());
            const data = docSnapshot.data()
            setLocationName(data.venueName)
            setReviewLink(data.googleReviewLink)
            if (data.venueImageUrl) {
              setLogoURL(data.venueImageUrl)
            }
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Error fetching document: ', error);
        }
      };
      fetchData();
    }
  }, [venueKey]);

  const [clickCount, setClickCount] = useState(0);

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };
  const handleNameChange = (event) => {
    setNameValue(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmailValue(event.target.value);
  };

  // useEffect(() => {
  //   const url = window.location.pathname;
  //   const parts = url.split('/'); // Split the pathname by slashes
  //   const valueAfterLastSlash = parts[parts.length - 1]; // Get the value after the last slash

  //   console.log(valueAfterLastSlash);

  //   setReviewKey(valueAfterLastSlash)
  // }, []);

  const handleBodyClick = () => {
    setClickCount((prevCount) => prevCount + 1);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setClickCount(0); // Reset the count after 10 seconds
    }, 5000); // 10 seconds
  };

  useEffect(() => {

    if (clickCount < 5) {
      document.body.addEventListener('click', handleBodyClick);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, [clickCount]);

  useEffect(() => {
    if (clickCount === 5) {
      // Once we have 5 clicks, do something
      localStorage.removeItem("lastDate")
      window.location.reload();
    }
  }, [clickCount]);

  const handleRating = (rate) => {
    logAction("rate", rate)
    setRatingValue(rate)
  }

  useEffect(() => {
    if (ratingValue === 5) {
      setDisplayState("google")
      localStorage.setItem('lastDate-' + venueKey, new Date().toISOString());
    } else if (ratingValue < 5 && ratingValue > 0) {
      setDisplayState("learning")
      localStorage.setItem('lastDate-' + venueKey, new Date().toISOString());
    }

  }

    , [ratingValue])



  async function logUniqueView() {

    try {
      const uniqueViewsRef = collection(firestore, 'uniqueViews'); // Access the 'feedback' collection
      const uniqueViewsDoc = await addDoc(uniqueViewsRef, {
        date: new Date().toISOString(),
        venueKey: venueKey,
        campaign,
        session: sessionId
      });
    } catch (error) {
      console.error('Error storing feedback:', error);
    }
  }

  async function logAction(action, value) {

    try {
      const actionsRef = collection(firestore, 'actions'); // Access the 'feedback' collection
      const actionsDoc = await addDoc(actionsRef, {
        date: new Date().toISOString(),
        action,
        venueKey: venueKey,
        value,
        campaign,
        session: sessionId
      });
    } catch (error) {
      console.error('Error storing feedback:', error);
    }
  }

  useEffect(() => {
    //  const reviewState = localStorage.getItem("reviewState")
    //  if (reviewState === "final") {
    //   setDisplayState("thankyoufinal")
    //  }

    const uniqueView = localStorage.getItem("uniqueView-" + venueKey)

    if (uniqueView === null) {
      localStorage.setItem("uniqueView-" + venueKey, new Date().toISOString())
      logUniqueView()
      setSessionId(translator.uuid());
    }
  }
    , [])


  useEffect(() => {
    const lastDate = localStorage.getItem('lastDate-' + venueKey);

    if (lastDate) {
      const currentDate = new Date();
      const storedDate = new Date(lastDate);
      const timeDifference = currentDate - storedDate;

      // 24 hours in milliseconds
      const twentyFourHours = 24 * 60 * 60 * 1000;

      if (timeDifference >= twentyFourHours) {
        setDisplayState("rating")
      } else {
        setDisplayState("thankyoufinal")
      }
    } else {
      setDisplayState("rating")
    }
  }, []);


  async function sendFeedback() {
    try {
      const feedbackRef = collection(firestore, 'feedback'); // Access the 'feedback' collection
      const feedbackDoc = await addDoc(feedbackRef, {
        name: nameValue,
        email: emailValue,
        text: textareaValue,
        date: new Date().toISOString(),
        rating: ratingValue,
        venueKey: venueKey,
        campaign: campaign,
        session: sessionId
      });
    } catch (error) {
      console.error('Error storing feedback:', error);
    }

    setDisplayState("thankyou")

  }

  function navigateToGoogle() {
    logAction("google", null)
    const url = reviewLink;
    window.location.href = url;
  }

  const primaryColor = "#f5ebe1"
  const secondaryColor = "#dbaa67"
  const boxShadow = false
  const startBoxHeight = "60px"

  const star = (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 2L11.45 11.22L1.28 12.69L8.64 19.87L6.9 30L16 25.22L25.1 30L23.36 19.87L30.72 12.7L20.55 11.22L16 2Z" fill={secondaryColor}/>
    </svg>
  );
  

  return (
    <div style={{display:"flex", flexDirection:"column", height:"100%" , overflow:"auto"}}>

    {logoURL && 
      <img src={logoURL} style={{ maxWidth: "100%", maxHeight: "120px", objectFit: "contain", marginTop:"20px"}} />
    }

    <div style={{ width: "100vw", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection:"column"}}>
      <div style={{ margin: "15px", paddingTop: 40, paddingLeft:15, paddingRight:15, paddingBottom:40, borderRadius: 30, ...(boxShadow ? { boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.75)" } : {}), display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", backgroundColor: primaryColor }}>

        {displayState === "rating" &&
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap:"10px"}}>
            <div style={{ fontWeight: '600 ', fontSize: '17px', textAlign: 'center', color:secondaryColor, marginBottom:"5px"}}>- BEWERTE UNS -</div>
            <div style={{ fontWeight: 'bold', fontSize: '24px', textAlign: 'center', color:"#363636", marginBottom:"20px"}}>Wir freuen uns auf dein Feedback :D</div>

            <div onClick={() => handleRating(5)} style={{display:"flex", marginBottom:"5px", boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.15)", justifyContent: "center", alignItems: "center", flexDirection: "row", backgroundColor:"white", borderRadius:"60px", width:"100%", height:startBoxHeight, gap:"3px"}}>
              {star}{star}{star}{star}{star}
            </div>
            
            <div onClick={() => handleRating(4)} style={{display:"flex", justifyContent: "center", alignItems: "center", flexDirection: "row", backgroundColor:"white", borderRadius:"60px", width:"100%", height:startBoxHeight, gap:"3px"}}>
              {star}{star}{star}{star}
            </div>

            <div onClick={() => handleRating(3)} style={{display:"flex", justifyContent: "center", alignItems: "center", flexDirection: "row", backgroundColor:"white", borderRadius:"60px", width:"100%", height:startBoxHeight, gap:"3px"}}>
              {star}{star}{star}
            </div>

            <div onClick={() => handleRating(2)} style={{display:"flex", justifyContent: "center", alignItems: "center", flexDirection: "row", backgroundColor:"white", borderRadius:"60px", width:"100%", height:startBoxHeight, gap:"3px"}}>
              {star}{star}
            </div>

            <div onClick={() => handleRating(1)} style={{display:"flex", justifyContent: "center", alignItems: "center", flexDirection: "row", backgroundColor:"white", borderRadius:"60px", width:"100%", height:startBoxHeight, gap:"3px"}}>
              {star}
            </div>

          </div>
        }

        {displayState === "learning" &&
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "10px"}}>

            <div style={{ fontWeight: 'bold', fontSize: '26px', textAlign: 'center', color: "#363636" }}>
              <span style={{ color: "#363636" }}>Vielen Dank für dein</span> <span style={{ color: secondaryColor }}>Feedback!</span>
            </div>
            <div style={{ fontWeight: '600 ', fontSize: '18px', textAlign: 'center', color:"#363636", marginBottom:"5px", lineHeight: '1.6'}}>Uns ist es wichtig, stetig an uns zu arbeiten & dass unsere Kunden zufrienden sind. Lass uns deine offene Meinung wissen. Teile deine Gedanken gerne mit uns, wir freuen uns auf deine Nachricht.</div>

            <div style={{display:"flex", backgroundColor:"white", borderRadius: 30, width:"100%",  justifyContent: "center", alignItems: "start", flexDirection: "column", paddingTop:"25px", paddingBottom:"25px", paddingLeft:"10px", paddingRight:"10px", gap:"10px", height:"auto"}}>
              <TextInput
                label="Dein Name"
                description=""
                placeholder="Max Mustermann"
                size="md"
                variant="filled"
                style={{width:"100%"}}
                onChange={handleNameChange}
              />

              <TextInput
                label="Email"
                description=""
                placeholder="max.mustermann@mail.de"
                size="md"
                variant="filled"
                style={{width:"100%"}}
                onChange={handleEmailChange}
              />

              <Textarea
                label="Deine offene Meinung"
                description=""
                placeholder="Mir gefällt/gefällt nicht..."
                size="md"
                variant="filled"
                resize="vertical"
                autosize
                maxRows={100}
                style={{width:"100%"}}
                minRows={4}
                onChange={handleTextareaChange}
              />

              <div onClick={() => sendFeedback()} style={{display:"flex", justifyContent: "center", alignItems: "center", flexDirection: "row", backgroundColor:secondaryColor, borderRadius:"60px", width:"100%", height:startBoxHeight, gap:"3px", color:"white", fontWeight: '500 ', fontSize: '18px', textAlign: 'center',marginTop:"10px"}}>
                Feedback senden
              </div>

            </div>
          </div>
        }

        {displayState === "thankyou" &&
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "10px"}}>
            <div style={{ fontWeight: 'bold', fontSize: '26px', textAlign: 'center', color: "#363636" }}>
              <span style={{ color: "#363636" }}>Vielen Dank für dein</span> <span style={{ color: secondaryColor }}>Feedback!</span>
            </div>
            <div style={{ fontWeight: '600 ', fontSize: '18px', textAlign: 'center', color:"#363636", marginBottom:"5px", lineHeight: '1.6'}}>Danke. Wir werden uns dein Feedback zu Herzen nehmen.</div>
          </div>
        }

        {displayState === "thankyoufinal" &&
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "10px"}}>
          <div style={{ fontWeight: 'bold', fontSize: '26px', textAlign: 'center', color: "#363636" }}>
            <span style={{ color: "#363636" }}>Danke für deine Bewertung!</span> <span style={{ color: secondaryColor }}></span>
          </div>
        </div>
        }

        {displayState === "google" &&
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "10px"}}>
            <div style={{ fontWeight: 'bold', fontSize: '26px', textAlign: 'center', color: "#363636" }}>
              <span style={{ color: "#363636" }}>Vielen Dank für dein</span> <span style={{ color: secondaryColor }}>Feedback!</span>
            </div>
            <div style={{ fontWeight: '600 ', fontSize: '18px', textAlign: 'center', color:"#363636", marginBottom:"5px", lineHeight: '1.6'}}>Vielen Dank. Noch ein Schritt: Wir würden uns riesig über eine Google Bewertung freuen.</div>

            <div onClick={() => navigateToGoogle()} style={{display:"flex", justifyContent: "center", alignItems: "center", flexDirection: "row", backgroundColor:secondaryColor, borderRadius:"60px", width:"100%", height:startBoxHeight, gap:"3px", color:"white", fontWeight: '500 ', fontSize: '18px', textAlign: 'center',marginTop:"10px"}}>
              Google Bewertung
            </div>
            </div>
        }

      </div>
    </div>
    </div>
  )
}

export default RatingScreen;
