// Import necessary modules from react-router-dom
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import RatingScreen from './RatingScreen';
import NewsletterSignup from './NewsletterSignup';
import EmailConfirmationSuccess from './EmailConfirmationSuccess';

import { createTheme, MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
const theme = createTheme({
  /** Put your mantine theme override here */
});

// Set up your main App component with the Router and Route components

const App = () => {
  return (
    <MantineProvider theme={theme}>
      <BrowserRouter>
        <Routes>
      
          <Route path="/newsletter/signup/:stationKey" element={<NewsletterSignup/>} />
          <Route path="/confirmation-success/:email" exact element={<EmailConfirmationSuccess/>} />
          <Route path="/:venueKey" element={<RatingScreen/>} />
        </Routes>
      </BrowserRouter>
    </MantineProvider>
  );
};

export default App;
