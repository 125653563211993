import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';

const EmailConfirmationSuccess = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const { email } = useParams();

  useEffect(() => {
    // Update time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Update the database to set confirmed = true for the provided email
  useEffect(() => {
    const updateConfirmationStatus = async () => {
      if (email) {
        const db = getFirestore();
        const emailDocRef = doc(db, 'emails', email);

        try {
          await updateDoc(emailDocRef, { confirmed: true });
          console.log('Email confirmation status updated for: ', email);
        } catch (error) {
          console.error('Error updating email confirmation status: ', error);
        }
      }
    };

    updateConfirmationStatus();
  }, [email]);

  const containerStyle = {
    textAlign: 'center',
    marginTop: '100px',
  };

  const successMessageStyle = {
    marginTop: '20px',
    padding: '20px',
    backgroundColor: '#d4edda',
    border: '1px solid #3c763d',
    color: '#155724',
    borderRadius: '5px',
  };

  const clockStyle = {
    fontSize: '24px',
    marginTop: '20px',
  };

  return (
    <div style={containerStyle}>
      <div style={successMessageStyle}>
        <h2>Deine E-Mail ist bestätigt</h2>
        <p>Danke für deine Bestätigung! Du kannst dir dein Geschenk an der Bar abholen</p>
      </div>
      <div style={clockStyle}>
        <strong>{currentTime.toLocaleTimeString()}</strong>
      </div>
    </div>
  );
};

export default EmailConfirmationSuccess;
