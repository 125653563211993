import React, { useState } from 'react';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { httpsCallable } from "firebase/functions";
import { functions } from './firebase';

const NewsletterSignup = () => {
  const [email, setEmail] = useState('');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const { stationKey } = useParams();
  console.log(stationKey)

  const handleSubscribe = async () => {
    // Validate email
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (!isValidEmail || !isCheckboxChecked) {
      // Handle invalid input, maybe show an error message
      return;
    }

    // Firebase Firestore setup
    const db = getFirestore();

    try {
      // Add email to the "emails" collection with confirmed set to false
      const newEmailRef = await addDoc(collection(db, 'emails'), {
        email,
        confirmed: false,
        registrationDate: serverTimestamp(),
        stationKey: stationKey, // Replace with the actual station key
      });

      const sendMailToUser = httpsCallable(functions, 'emailServices-sendMailToUser');
            sendMailToUser({language: "de", template: 'confirmationEmailTemplate', extraData: {
                email: email,
                docId: newEmailRef.id
            }})
            .then((result) => {
                // Read result of the Cloud Function.
                /** @type {any} */
                const data = result.data;
                const sanitizedMessage = data.text;
                // resolve(sanitizedMessage)
            })

      // Update the state or perform any additional actions
      console.log('Document added with ID: ', newEmailRef.id);

      // Set state or perform additional actions here

      // In this example, we're setting subscribed to true to show the success message
      setSubscribed(true);
    } catch (error) {
      console.error('Error adding document: ', error);
      // Handle error, maybe show an error message to the user
    }
  };

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);

    // Basic email validation
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail);
    setIsValidEmail(isValid);
  };

  const containerStyle = {
    textAlign: 'center',
    marginTop: '100px',
  };

  const successMessageStyle = {
    marginTop: '20px',
    padding: '20px',
    backgroundColor: '#dff0d8',
    border: '1px solid #3c763d',
    color: '#3c763d',
    borderRadius: '5px',
  };

  const formStyle = {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '5px',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    boxSizing: 'border-box',
  };

  const checkboxContainerStyle = {
    marginBottom: '15px',
  };

  const buttonStyle = {
    padding: '10px 15px',
    backgroundColor: isCheckboxChecked && isValidEmail ? '#337ab7' : '#ccc',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: isCheckboxChecked && isValidEmail ? 'pointer' : 'not-allowed',
    transition: 'background-color 0.3s ease', // Smooth transition
  };

  return (
    <div style={containerStyle}>
      {subscribed ? (
        <div style={successMessageStyle}>
          <h2>Vielen Dank!</h2>
          <p>
            Wir haben dir eine E-Mail geschickt. Sobald du deine E-Mail bestätigt hast, erhältst du dein Geschenk.
          </p>
        </div>
      ) : (
        <div style={formStyle}>
          <h2>E-Mail-Adresse eingeben</h2>
          <input
            type="email"
            placeholder="Deine E-Mail-Adresse"
            value={email}
            onChange={handleEmailChange}
            style={inputStyle}
          />
          <div style={checkboxContainerStyle}>
            <label>
              <input type="checkbox" id="subscribe" checked={isCheckboxChecked} onChange={handleCheckboxChange} />
              Ich möchte den Newsletter abonnieren
            </label>
          </div>
          <button
            onClick={handleSubscribe}
            style={buttonStyle}
            disabled={!isCheckboxChecked || !isValidEmail}
          >
            Anmelden
          </button>
        </div>
      )}
    </div>
  );
};

export default NewsletterSignup;
